import { getPage } from "../data-interface/sanity";
import PageContent from "../components/PageContent/PageContent";

export default function IndexPage({ pageData }) {
    return <PageContent content={pageData.content} />;
}

export async function getStaticProps() {
    const pageData = await getPage("home");

    return {
        props: {
            pageData,
        },
        revalidate: parseInt(process.env.REVALIDATE),
    };
}
